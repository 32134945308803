import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from 'react-modal';
import { Pagination } from '@material-ui/lab';

import '../assets/CustomTable.css';
import '../assets/Worksites.css';
import '../assets/Modal.css';
import { API_URL } from '../config';
import Contacts from './Contacts';
import WorksitesLoader from './WorksitesLoader';

const defaultModalStyles = {
    content: {
        top: '52%',
        left: '50%',
        right: '50%',
        bottom: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: '180px',
        display: 'flex'
    }
};

const Worksites = ({
    worksitesData,
    apiKey,
    resultsForHubspot,
    setResultsForHubspot,
    worksitesWithDetails,
    setWorksitesWithDetails,
    handlePageChange,
    worksitesLoading,
    selectedWorksitePage,
    setShowError,
    setErrorMessage,
    setFatalError
}) => {
    const [worksites, setWorksites] = useState(worksitesData?.worksites);
    const [contacts, setContacts] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalWorksiteId, setModalWorksiteId] = useState();
    const [selectedWorksiteId, setSelectedWorksiteId] = useState();
    const [worksiteIdDataFetchingInProgress, setWorksiteIdDataFetchingInProgress] = useState(null);
    const [numberOfContactsForSelectedWorksite, setNumberOfContactsForSelectedWorksite] = useState(0);

    useEffect(() => {
        if (!worksitesData) {
            setSelectedWorksiteId(null);
            setContacts(null);
        }
        setWorksites(worksitesData?.worksites);
    }, [worksitesData]);

    const getWorksiteDataFromBisnode = async (worksiteId) => {
        const { numberOfContacts } = worksites[worksiteId];

        if (worksitesWithDetails[worksiteId]) {
            return worksitesWithDetails[worksiteId];
        }

        setNumberOfContactsForSelectedWorksite(numberOfContacts);
        setWorksiteIdDataFetchingInProgress(worksiteId);

        let worksiteDetails;
        try {
            worksiteDetails = await axios.post(
                `${API_URL}/api/company/getWorksiteDataFromBisnode`,
                { apiKey, worksiteId, numberOfContacts }
            );
            setWorksitesWithDetails({ ...worksitesWithDetails, [worksiteId]: worksiteDetails.data });
        } catch (error) {
            setErrorMessage('Error loading details and contacts for selected worksite! Please search again.');
            setShowError(true);
            setFatalError(true);
        }
        setWorksiteIdDataFetchingInProgress(null);

        return worksiteDetails?.data || {};
    };

    const selectWorksite = async (worksiteId) => {
        if (worksiteId === selectedWorksiteId) {
            return;
        }

        setContacts(null);
        setSelectedWorksiteId(worksiteId);

        const worksite = await getWorksiteDataFromBisnode(worksiteId);
        const filteredContacts = worksite?.contacts?.filter(contact => contact != null);

        setContacts(filteredContacts);
    };

    const closeModal = () => {
        setWorksiteIdDataFetchingInProgress(null);
        setModalIsOpen(false);
    };

    const deselectCompany = (worksiteId) => {
        const newResultsForHubspot = { ...resultsForHubspot };
        delete newResultsForHubspot[worksiteId];
        setResultsForHubspot(newResultsForHubspot);
        setWorksiteIdDataFetchingInProgress(null);
        setModalIsOpen(false);
    };

    const handleCompanyCheckbox = async (event, worksiteId, checked) => {
        event.stopPropagation();
        await selectWorksite(worksiteId);
        const worksite = await getWorksiteDataFromBisnode(worksiteId);
        const newResultsForHubspot = { ...resultsForHubspot };

        if (checked) {
            newResultsForHubspot[worksiteId] = { ...worksite, contacts: {} };
            setResultsForHubspot(newResultsForHubspot);
            return;
        }

        if (!checked && Object.keys(newResultsForHubspot[worksiteId].contacts).length) {
            setModalWorksiteId(worksiteId);
            setModalIsOpen(true);
            return;
        }

        deselectCompany(worksiteId);
    };

    const handleContactCheckbox = async (contact, checked) => {
        const newResultsForHubspot = { ...resultsForHubspot };
        const worksiteId = contact.worksite?.id;

        if (checked) {
            if (!newResultsForHubspot[worksiteId]) {
                const worksite = await getWorksiteDataFromBisnode(worksiteId);
                newResultsForHubspot[worksiteId] = { ...worksite, contacts: {}, addedBySelectingContact: true };
            }
            newResultsForHubspot[worksiteId].contacts[contact.id] = contact;
        } else {
            delete newResultsForHubspot[worksiteId].contacts[contact.id];
            if (newResultsForHubspot[worksiteId].addedBySelectingContact &&
                Object.keys(newResultsForHubspot[worksiteId].contacts).length === 0) {
                delete newResultsForHubspot[worksiteId];
            }
        }

        setResultsForHubspot(newResultsForHubspot);
    };

    const renderEmptyRow = (key) => (
        <tr key={`worksites_empty_row_${key}`} className="empty-row">
            {[...Array(9)].map((x, i) => <td key={`worksites_empty_cell_${key}_${i}`}/>)}
        </tr>
    );

    const renderWorksites = () => {
        if (worksitesLoading) {
            return <WorksitesLoader/>;
        }

        return (
            <table className="custom-table worksites-table">
                <thead>
                    <tr>
                        <th className="header1">Create</th>
                        <th className="header2">CRM</th>
                        <th className="header3">Name</th>
                        <th className="header4">City</th>
                        <th className="header5">Visiting Address</th>
                        <th className="header6">Visiting City</th>
                        <th className="header7">Status</th>
                        <th className="header8">HQ</th>
                        <th className="header9">Contacts</th>
                    </tr>
                </thead>
                <tbody>
                    {!worksites || !Object.keys(worksites).length ?
                        [...Array(10)].map((x, i) => renderEmptyRow(`default_${i}`)) :
                        <>
                            {Object.values(worksites).map(worksite => (
                                <tr
                                    key={worksite.id}
                                    className={`${selectedWorksiteId === worksite.id ? 'selected' : ''} clickable`}
                                    onClick={() => selectWorksite(worksite.id)}
                                >
                                    <td>
                                        {worksiteIdDataFetchingInProgress === worksite.id ?
                                            <span>
                                                <ClipLoader
                                                    size="12px"
                                                    color={selectedWorksiteId === worksite.id ? 'white' : 'navy'}
                                                />
                                            </span> :
                                            <input
                                                type="checkbox"
                                                checked={!!resultsForHubspot[worksite.id]}
                                                value={worksite.id}
                                                onClick={(event) => event.stopPropagation()}
                                                onChange={(event) => handleCompanyCheckbox(event, worksite.id, event.target.checked)}
                                            />}
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={!!worksite.existInDb}
                                            readOnly
                                        />
                                    </td>
                                    <td>{worksite.name}</td>
                                    <td>{worksite.postalAddress?.city}</td>
                                    <td>{worksite.visitingAddress?.address}</td>
                                    <td>{worksite.visitingAddress?.city}</td>
                                    <td>{worksite.status.name}</td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={worksite.type?.code === 'TY10'} // 'TY10' is Bisnode code for Head office
                                            readOnly
                                        />
                                    </td>
                                    <td>{worksite.numberOfContacts}</td>
                                </tr>
                            ))}
                            {Object.values(worksites).length < 10 &&
                                [...Array(10 - Object.values(worksites).length)].map((x, i) => renderEmptyRow(`additional_${i}`))}
                        </>
                    }
                </tbody>
            </table>);
    };

    return (
        <div>
            <Modal isOpen={modalIsOpen} style={defaultModalStyles}>
                <div className="modal-content">
                    <p>
                        Warning!. You are about to deselect company with selected contacts.
                        If you continue all contacts which belongs to this company will be deselected.
                    </p>
                    <div className="button-wrapper">
                        <button
                            className="modal-cancel-button"
                            type="button"
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="modal-continue-button"
                            type="button"
                            onClick={() => deselectCompany(modalWorksiteId)}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </Modal>
            <h4>{`Offices (${worksitesData?.hitsTotal || 0}):`}</h4>
            {renderWorksites()}
            <Pagination
                count={worksitesData?.hitsTotal ? Math.ceil(worksitesData?.hitsTotal / 10) : 0}
                shape="rounded"
                variant="outlined"
                color="secondary"
                showFirstButton
                showLastButton
                onChange={handlePageChange}
                page={selectedWorksitePage}
            />
            <Contacts
                contacts={contacts}
                handleContactCheckbox={handleContactCheckbox}
                resultsForHubspot={resultsForHubspot}
                contactsLoading={worksiteIdDataFetchingInProgress !== null}
                contactsCount={numberOfContactsForSelectedWorksite}
            />
        </div>
    );
};

export default Worksites;
